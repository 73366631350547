import React from 'react';
import { useTranslation } from 'react-i18next';
import { LinkSize, LinkWeight } from '@Components/atoms/Link/Link';
import { useGlobalContext } from '@Components/context/GlobalContext';
import { PropsWithClassName } from '@Components/helper';
import { getDynamicSubRoute } from '@Components/helper/url';
import { Routes } from '@Components/Routes';
import useRouter from '@Helpers/i18n/useRouter';
import { TextSize } from '@Helpers/types/text';
import { Root, StyledLink, StyledText, Username } from './UserDropDown.styles';
interface Props extends PropsWithClassName {
  handleCloseSidebar: () => void;
}
const UserDropDown = (props: Props): React.ReactElement => {
  const {
    className,
    dataTestId,
    handleCloseSidebar
  } = props;
  const {
    t
  } = useTranslation('global');
  const {
    user,
    logout
  } = useGlobalContext();
  const router = useRouter();
  const handleLogout = () => {
    logout();
    handleCloseSidebar();
    router.push('/');
  };
  if (!user) {
    return <div data-testid={props.dataTestId ?? 'user-drop-down-root'}></div>;
  }
  return <Root className={className} data-testid={dataTestId ?? 'user-drop-down-root'} data-sentry-element="Root" data-sentry-component="UserDropDown" data-sentry-source-file="UserDropDown.tsx">
      <Username size={TextSize.Medium} dataTestId="link-username-root" data-sentry-element="Username" data-sentry-source-file="UserDropDown.tsx">
        {user.username}
      </Username>
      <StyledLink href={getDynamicSubRoute(Routes.User, user.username.toLowerCase())} onClick={handleCloseSidebar} weight={LinkWeight.Light} size={LinkSize.Medium} hideUnderline dataTestId="link-profile-root" data-sentry-element="StyledLink" data-sentry-source-file="UserDropDown.tsx">
        {t('navigation.header.profile')}
      </StyledLink>
      <StyledLink href={Routes.Settings} onClick={handleCloseSidebar} weight={LinkWeight.Light} size={LinkSize.Medium} hideUnderline dataTestId="link-settings-root" data-sentry-element="StyledLink" data-sentry-source-file="UserDropDown.tsx">
        {t('navigation.header.settings')}
      </StyledLink>
      {(user?.isAdmin() || user?.isModerator()) && <StyledLink href={Routes.ModeratorsTypingTestResults} onClick={handleCloseSidebar} weight={LinkWeight.Light} size={LinkSize.Medium} hideUnderline dataTestId="link-moderators-root">
          {t('navigation.header.moderator')}
        </StyledLink>}
      <StyledText onClick={handleLogout} size={TextSize.Medium} dataTestId="link-logout-root" data-sentry-element="StyledText" data-sentry-source-file="UserDropDown.tsx">
        {t('navigation.header.logout')}
      </StyledText>
    </Root>;
};
export default UserDropDown;