import React from 'react';
import { useDeleteNotifications, useReadNotifications } from '@Api/services/NotificationService';
import { useGlobalContext } from '@Components/context/GlobalContext';
import { PropsWithClassName } from '@Components/helper';
import { useGlobalRewards } from '@Components/helper/reward/useRewards';
import { notificationToast } from '@Components/helper/toastHelper';
import { useNotificationPolling } from '@Components/helper/useNotificationPolling';
interface Props extends PropsWithClassName {
  children: React.ReactElement;
}
const NotificationToastContainer = (props: Props): React.ReactElement => {
  const {
    user
  } = useGlobalContext();
  useGlobalRewards();
  const {
    executeRequest: markAsReadExecuteRequest
  } = useReadNotifications();
  const {
    executeRequest: deleteNotificationExecuteRequest
  } = useDeleteNotifications();
  useNotificationPolling(user, 'toast', false, async notifications => {
    notifications.forEach(notification => notificationToast(notification.message, notification));
    const data = notifications.map(notification => {
      return {
        notificationId: notification.id
      };
    });
    if (data.length === 0) {
      return;
    }
    await markAsReadExecuteRequest({
      body: {
        data
      }
    });
    await deleteNotificationExecuteRequest({
      body: {
        data
      }
    });
  });
  return props.children;
};
export { NotificationToastContainer };