import React from 'react';
import { PropsWithClassName } from '@Components/helper';
import { Root } from './LoadingSpinner.styles';
interface Props extends PropsWithClassName {
  size?: LoadingSpinnerSize;
  invertColors?: boolean;
  isPositionAbsolute?: boolean;
}
export enum LoadingSpinnerSize {
  Default = '24px',
  Large = '36px',
}
const LoadingSpinner = (props: Props): React.ReactElement => {
  const {
    size,
    invertColors,
    isPositionAbsolute
  } = props;
  return <Root size={size ?? LoadingSpinnerSize.Default} invertColors={invertColors ?? false} isPositionAbsolute={isPositionAbsolute ?? false} className={props.className} data-testid={props.dataTestId ?? 'LoadingSpinner-root'} data-sentry-element="Root" data-sentry-component="LoadingSpinner" data-sentry-source-file="LoadingSpinner.tsx"></Root>;
};
export { LoadingSpinner };