'use client';

import { useCallback, useState } from 'react';

import { ApiError } from '@Api/ApiError';
import { ApiValidationError } from '@Api/ApiValidationError';

import { useGlobalContext } from '@Components/context/GlobalContext';

import ValidationErrorModel from './models/ValidationErrorModel';

export function useApi<RequestOptions, Model, ApiSchema>(
  requestCallback: (options: RequestOptions) => Promise<{
    error?: unknown;
    data?: ApiSchema;
    request: Request;
    response: Response;
  }>,
  hydrate: (response: ApiSchema) => Model,
  onSuccess?: (result: Model) => void,
  onError?: (error: ApiError) => void,
  onValidationError?: (validationError: ValidationErrorModel) => void
) {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<Model | null>(null);
  const [error, setError] = useState<ApiError | null>(null);
  const [validationError, setValidationError] = useState<ValidationErrorModel | null>(null);

  const { logout } = useGlobalContext();

  const executeRequest = useCallback(
    async function (options: RequestOptions) {
      setLoading(true);
      setValidationError(null);
      setError(null);

      try {
        const response = await requestCallback(options);

        catchErrorCodes(response.request, response.response, response.error, response.data);

        if (!response.data) {
          throw new Error('Unexpected error: Can data exist with error?');
        }

        const hydratedResponse = hydrate(response.data);

        setResult(hydratedResponse);
        if (onSuccess) {
          onSuccess(hydratedResponse);
        }
      } catch (error) {
        if (error instanceof ApiValidationError) {
          setValidationError(error.response);
          if (onValidationError) {
            onValidationError(error.response);
          }
        } else if (error instanceof ApiError) {
          setError(error);
          if (onError) {
            onError(error);
          }
          if (error.status === 403 || error.status === 401) {
            logout();
          }
        } else {
          // todo: replace with better logging
          console.error(error);
        }
      }

      setLoading(false);
    },
    [hydrate, requestCallback, logout]
  );

  return { loading, result, error, validationError, executeRequest };
}

// state changes in useApi can lead to unnecessary rerenders, so in situations where performance is important we can use this hook
export function useApiWithoutStates<RequestOptions, Model, ApiSchema>(
  requestCallback: (options: RequestOptions) => Promise<{
    error?: unknown;
    data?: ApiSchema;
    request: Request;
    response: Response;
  }>,
  hydrate: (response: ApiSchema) => Model,
  onSuccess?: (result: Model) => void,
  onError?: (error: ApiError) => void,
  onValidationError?: (validationError: ValidationErrorModel) => void
) {
  const { logout } = useGlobalContext();

  const executeRequest = useCallback(
    async function (options: RequestOptions) {
      try {
        const response = await requestCallback(options);

        catchErrorCodes(response.request, response.response, response.error, response.data);

        if (!response.data) {
          throw new Error('Unexpected error: Can data exist with error?');
        }

        const hydratedResponse = hydrate(response.data);

        if (onSuccess) {
          onSuccess(hydratedResponse);
        }
      } catch (error) {
        if (error instanceof ApiValidationError) {
          if (onValidationError) {
            onValidationError(error.response);
          }
        } else if (error instanceof ApiError) {
          if (onError) {
            onError(error);
          }
          if (error.status === 403 || error.status === 401) {
            logout();
          }
        } else {
          // todo: replace with better logging
          console.error(error);
        }
      }
    },
    [hydrate, requestCallback] // todo: add logout as dependency as soon as re-render mr 141 is merged && add callbacks
  );

  return { executeRequest };
}

const catchErrorCodes = (request: Request, response: Response, error?: unknown, data?: unknown): void => {
  if (data && error) {
    throw new Error('Unexpected error: Can data exist with error?');
  }

  if (data) {
    return;
  }

  if (
    typeof error !== 'object' ||
    error === null ||
    !('code' in error) ||
    typeof error.code !== 'number' ||
    !('message' in error) ||
    typeof error.message !== 'string' ||
    !('data' in error)
  ) {
    throw new ApiError(request, response, 'Unexpected Error: error has not expected structure' + error);
  }

  if (error.code === 422) {
    throw new ApiValidationError(request, response, { message: error.message, data: error.data, code: error.code });
  }

  throw new ApiError(request, response, error.message);
};
