import { IconMoon, IconSun } from '@tabler/icons-react';
import React, { useMemo } from 'react';
import ThemeModel from '@Api/models/ThemeModel';
import { Icon } from '@Components/atoms/Icon/Icon';
import ThemePreview from '@Components/atoms/ThemePreview';
import { useGlobalContext } from '@Components/context/GlobalContext';
import { PropsWithClassName } from '@Components/helper';
import { slugify } from '@Components/helper/url';
import { TextColor, TextSize } from '@Helpers/types/text';
import { getDefaultTheme, ThemeType } from '@Assets/styles/theme/theme';
import { Root, StyledRow, StyledText, ThemeGroup } from './ThemeSwitcher.styles';
interface Props extends PropsWithClassName {}
const ThemeSwitcher = (props: Props): React.ReactElement => {
  const {
    changeCurrentTheme,
    themes,
    currentTheme
  } = useGlobalContext();
  const darkThemes = useMemo(() => themes.filter(theme => theme.themeType === ThemeType.Dark), [themes]);
  const lightThemes = useMemo(() => themes.filter(theme => theme.themeType === ThemeType.Light), [themes]);
  const renderThemes = (themes: ThemeModel[]) => themes.map(theme => <StyledRow key={theme.id} onClick={() => changeCurrentTheme(theme)} data-testid={`${slugify(theme.name)}${theme.id === currentTheme?.id ? '-selected' : ''}-root`}>
        <StyledText size={TextSize.Medium} color={theme.id === currentTheme?.id ? TextColor.ThemePrimary : TextColor.Header}>
          {theme.name}
        </StyledText>
        <ThemePreview theme={getDefaultTheme(theme)} baseColors={theme} />
      </StyledRow>);
  return <Root className={props.className} data-testid={props.dataTestId ?? 'theme-switcher-root'} data-sentry-element="Root" data-sentry-component="ThemeSwitcher" data-sentry-source-file="ThemeSwitcher.tsx">
      <ThemeGroup data-sentry-element="ThemeGroup" data-sentry-source-file="ThemeSwitcher.tsx">
        <Icon icon={IconSun} color={TextColor.Header} size={24} data-sentry-element="Icon" data-sentry-source-file="ThemeSwitcher.tsx" />
        {renderThemes(lightThemes)}
      </ThemeGroup>
      <ThemeGroup data-sentry-element="ThemeGroup" data-sentry-source-file="ThemeSwitcher.tsx">
        <Icon icon={IconMoon} color={TextColor.Header} size={24} data-sentry-element="Icon" data-sentry-source-file="ThemeSwitcher.tsx" />
        {renderThemes(darkThemes)}
      </ThemeGroup>
    </Root>;
};
export default ThemeSwitcher;