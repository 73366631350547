import { IconSettings } from '@tabler/icons-react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import NotificationModel from '@Api/models/NotificationModel';
import { useDeleteNotifications, useReadNotifications } from '@Api/services/NotificationService';
import Headline from '@Components/atoms/Headline';
import IconButton from '@Components/atoms/IconButton';
import LoadingSpinner from '@Components/atoms/LoadingSpinner';
import { LoadingSpinnerSize } from '@Components/atoms/LoadingSpinner/LoadingSpinner';
import Row from '@Components/atoms/Row';
import { useGlobalContext } from '@Components/context/GlobalContext';
import { PropsWithClassName } from '@Components/helper';
import { unexpectedApiError } from '@Components/helper/error';
import NotificationCenterRow from '@Components/molecules/NotificationCenterRow/NotificationCenterRow';
import { Routes } from '@Components/Routes';
import useRouter from '@Helpers/i18n/useRouter';
import { AntiCheatGameResult } from '@Helpers/result';
import { TextColor, TextSize } from '@Helpers/types/text';
import { DeleteAllButton, EmptyNotifications } from './NotificationCenter.styles';
interface Props extends PropsWithClassName {
  handleCloseSidebar: () => void;
  notifications: NotificationModel[];
  refreshNotifications: () => void;
  handleAntiCheatResultUpdate: (result: AntiCheatGameResult) => void;
}
const NotificationCenter = (props: Props): React.ReactElement => {
  const {
    notifications,
    refreshNotifications,
    handleCloseSidebar,
    handleAntiCheatResultUpdate
  } = props;
  const {
    t
  } = useTranslation('global');
  const router = useRouter();
  const {
    user,
    updateUsername,
    updateGravatar
  } = useGlobalContext();
  useEffect(() => {
    if (notifications.length === 0 || !user) {
      return;
    }
    const notification = notifications[0];
    if (notification.user.username !== user.username) {
      updateUsername(notification.user.username);
    }
    if (notification.user.gravatar.medium !== user.gravatar.medium) {
      updateGravatar(notification.user.gravatar);
    }
  }, [notifications, updateGravatar, updateUsername, user]);
  useEffect(() => {
    const unreadNotifications = notifications.filter(notification => notification.readAt === null);
    if (unreadNotifications.length === 0) {
      return;
    }
    markAsReadExecuteRequest({
      body: {
        data: unreadNotifications.map(unreadNotification => {
          return {
            notificationId: unreadNotification.id
          };
        })
      }
    }).catch(unexpectedApiError);
  }, []);
  const {
    executeRequest: markAsReadExecuteRequest
  } = useReadNotifications();
  const {
    executeRequest: deleteNotificationExecuteRequest
  } = useDeleteNotifications();
  const handleDelete = async (notificationId: string) => {
    await deleteNotificationExecuteRequest({
      body: {
        data: [{
          notificationId
        }]
      }
    });
    await refreshNotifications();
  };
  const handleDeleteAll = async () => {
    if (!notifications) {
      return;
    }
    await deleteNotificationExecuteRequest({
      body: {
        data: notifications.map(unreadNotification => {
          return {
            notificationId: unreadNotification.id
          };
        })
      }
    });
    await refreshNotifications();
  };
  const renderedNotifications = () => {
    if (!notifications) {
      return <LoadingSpinner size={LoadingSpinnerSize.Large} />;
    }
    if (notifications.length === 0) {
      return <EmptyNotifications size={TextSize.Small} color={TextColor.Header} dataTestId="empty-notifications-message">
          {t('notifications.empty')}
        </EmptyNotifications>;
    }
    return <>
        {notifications?.map((notification: NotificationModel, index) => <NotificationCenterRow showAntiCheatModal={handleAntiCheatResultUpdate} key={notification.id} dataTestId={`notification-center-row-${index + 1}`} notification={notification} onDeleteClick={handleDelete} handleCloseSidebar={handleCloseSidebar} />)}
        <Row noGutters xsEnd data-sentry-element="Row" data-sentry-source-file="NotificationCenter.tsx">
          <DeleteAllButton size={TextSize.Xs} onClick={handleDeleteAll} dataTestId="notification-delete-all-button" data-sentry-element="DeleteAllButton" data-sentry-source-file="NotificationCenter.tsx">
            {t('notifications.delete_all')}
          </DeleteAllButton>
        </Row>
      </>;
  };
  return <div className={props.className} data-testid={props.dataTestId ?? 'notification-center-root'} data-sentry-component="NotificationCenter" data-sentry-source-file="NotificationCenter.tsx">
      <Row noGutters xsBetween data-sentry-element="Row" data-sentry-source-file="NotificationCenter.tsx">
        <Headline importance={4} color={TextColor.Header} data-sentry-element="Headline" data-sentry-source-file="NotificationCenter.tsx">
          {t('notifications.headline')}
        </Headline>
        <IconButton onClick={() => {
        handleCloseSidebar();
        router.push(Routes.SettingsNotifications);
      }} color={TextColor.Header} dataTestId="notification-center-settings" data-sentry-element="IconButton" data-sentry-source-file="NotificationCenter.tsx">
          <IconSettings stroke={1} data-sentry-element="IconSettings" data-sentry-source-file="NotificationCenter.tsx" />
        </IconButton>
      </Row>
      {renderedNotifications()}
    </div>;
};
export default NotificationCenter;