'use client';

import { client } from '@hey-api/client-fetch';
import { useCallback, useEffect, useRef, useState } from 'react';

import useRewardStore from '@Root/src/store/useRewardStore';

import { LocalStorageUserModel } from '@Api/localStorage/LocalStorageUserModel';
import { SettingsModel } from '@Api/localStorage/SettingsModel';
import { TypingBoxSettingsModel } from '@Api/localStorage/TypingBoxSettingsModel';
import LanguageModel from '@Api/models/LanguageModel';
import ThemeModel from '@Api/models/ThemeModel';
import UserModel from '@Api/models/UserModel';
import { useGetUserMe, useUpdateProfileTheme } from '@Api/services/AccountService';
import { useListUserAchievementAllowedStatus } from '@Api/services/AchievementService';
import { useListLanguages } from '@Api/services/LanguageService';
import { useListThemes } from '@Api/services/ThemeService';

import { refresh } from '@Api-generated';

import { UserImageSrcModel } from '@Components/atoms/UserImage/UserImage';
import {
  deleteLocalStorageAuthorization,
  deleteLocalStorageUser,
  getLocalStorageAuthorization,
  getLocalStorageRefreshToken,
  getLocalStorageSettings,
  getLocalStorageTypingBoxSettings,
  setLocalStorageAuthorization,
  setLocalStorageRefreshToken,
  setLocalStorageSettings,
  setLocalStorageTypingBoxSettings,
  setLocalStorageUser,
} from '@Components/context/LocalStorage';
// import { CookieKey, getCookieValue } from '@Components/helper/cookie';
import { unexpectedApiError } from '@Components/helper/error';
import { jwtDecode } from '@Components/helper/jwt';
import { DEFAULT_LANGUAGE_ISO } from '@Components/helper/language';
import { errorToast } from '@Components/helper/toastHelper';
import { TypingTestFontSize } from '@Components/organisms/TypingBox/TypingBox';

import {
  deleteCookieAuthorization,
  deleteCookieRefreshToken,
  deleteCookieUser, // setCookieAuthorization,
  setCookiesAuthorization,
  setCookieUser,
} from '../../api-next/cookie';

const typingBoxDefaultSettings = {
  fontFace: 'Roboto Slab, serif',
  fontSize: TypingTestFontSize.Medium,
  rows: 2,
  useHighContrast: false,
  useTimer: true,
  showStats: false,
  showAdvancedResult: false,
  showInvisibleChars: false,
};

export function useGlobalContextHelper(lng: string, initialUser?: LocalStorageUserModel) {
  const [isGlobalContextInitialized, setIsGlobalContextInitialized] = useState<boolean>(false);
  // todo: a ref is used here to avoid unwanted re-renderings.
  // The better solution would be to improve the re-render behaviour of the global context, but this should be a separate task after the alpha start
  const cookieUser = useRef(initialUser);

  const [loggedIn, setLoggedIn] = useState<boolean | null>(null);
  const [languages, setLanguages] = useState<LanguageModel[]>([]);
  const [themes, setThemes] = useState<ThemeModel[]>([]);
  const [user, setUser] = useState<LocalStorageUserModel | null>(null);
  const [currentTheme, setCurrentTheme] = useState<ThemeModel | undefined>(undefined);
  const [authorizationHeader, setAuthorizationHeader] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<string | null>(null);
  const [refreshTokenExpiration, setRefreshTokenExpiration] = useState<number | null>(null);
  const [typingBoxSettings, setTypingBoxSettings] = useState<TypingBoxSettingsModel>(
    TypingBoxSettingsModel.hydrateFromObject(typingBoxDefaultSettings)
  );

  const resetRewardStore = useRewardStore((state) => state.reset);

  // todo cookie approach
  // const getAuthenticationHeader = (): string | null | undefined => {
  //   let authData: string | null | undefined = authorizationHeader;
  //   console.log(authData);
  //   if (!authData) {
  //     authData = getCookieValue(CookieKey.Authorization);
  //   }
  //   return authData;
  // };
  // const getRefreshToken = (): string | null | undefined => {
  //   let token: string | null | undefined = refreshToken;
  //   if (!token) {
  //     token = getCookieValue(CookieKey.RefreshToken);
  //   }
  //   return token;
  // };

  const removeAuthorizationHeaderForRequests = (): void => {
    client.interceptors.request.use((request) => {
      request.headers.delete('Authorization');
      return request;
    });
  };

  const setAuthorizationHeaderForRequests = (authorizationHeader: string, refreshToken: string | undefined): void => {
    client.interceptors.request.use(async (request) => {
      if (refreshToken === undefined) {
        const token = getLocalStorageRefreshToken();
        if (token && token.refreshToken) {
          refreshToken = token.refreshToken;
        }
      }
      // dont call refresh on refresh
      if (request.url.includes('token/refresh')) {
        return request;
      }
      // todo we decode jwt twice. Can we streamline this? see: app/src/middleware.ts:48
      const decodedJwt = jwtDecode(authorizationHeader);
      const MAX_TIME_REFRESH = 60 * 1000;
      // if token expires in 1h -> refresh
      if (decodedJwt.exp - (Date.now() + MAX_TIME_REFRESH) / 1000 < 0) {
        if (refreshToken) {
          const refreshResponse = await refresh({
            body: {
              refresh_token: refreshToken,
            },
          });
          if (
            refreshResponse &&
            refreshResponse.data?.data.refreshToken &&
            refreshResponse.data?.data.refreshTokenExpiration
          ) {
            authorizationHeader = refreshResponse.data.data.token;
            setLocalStorageRefreshToken(
              refreshResponse.data.data.refreshToken,
              refreshResponse.data.data.refreshTokenExpiration
            );
            setLocalStorageAuthorization(authorizationHeader);
            setAuthorizationHeader(authorizationHeader);
          }
        }
      }
      request.headers.set('Authorization', 'Bearer ' + authorizationHeader);
      return request;
    });
  };

  // typingBoxSettings change handling
  const updateTypingBoxSettingsAsJson = (typingBoxSettingsAsJson: string) => {
    setTypingBoxSettings(TypingBoxSettingsModel.hydrateFromJson(typingBoxSettingsAsJson));
    setLocalStorageTypingBoxSettings(typingBoxSettingsAsJson);
  };

  const updateTypingBoxSettings = useCallback((settings: TypingBoxSettingsModel) => {
    updateTypingBoxSettingsAsJson(settings.serialize());
  }, []);

  const initTypingBoxSettings = useCallback(() => {
    const localConfigRaw = getLocalStorageTypingBoxSettings();

    if (!localConfigRaw) {
      return;
    }

    const initialSettings = {
      ...typingBoxDefaultSettings,
      ...JSON.parse(localConfigRaw),
    };

    updateTypingBoxSettings(TypingBoxSettingsModel.hydrateFromJson(JSON.stringify(initialSettings)));
  }, [updateTypingBoxSettings]);

  // achievements
  const { executeRequest: listUserAchievementAllowedStatusExecuteRequest } = useListUserAchievementAllowedStatus(
    (result) => {
      const rewardMap: { [key: string]: boolean } = {};

      result.forEach((item) => (rewardMap[item.achievementType] = item.isAchieved));

      useRewardStore.setState({ rewardMap });
    }
  );

  // theming
  const setCurrentThemeById = useCallback(
    (themeId?: string, localThemes: ThemeModel[] = themes) => {
      const currentTheme = localThemes.find((theme) => theme.id === themeId);

      if (currentTheme === undefined) {
        const primaryTheme = localThemes.find((theme) => theme.isPrimary === true);
        if (primaryTheme === undefined) {
          // should never happen
          throw Error('Unexpected Error: no primary theme found');
        }
        setCurrentTheme(primaryTheme);
        setLocalStorageSettings(SettingsModel.hydrateFromObject({ themeId: primaryTheme.id }));
        return;
      }

      setCurrentTheme(currentTheme);
      setLocalStorageSettings(SettingsModel.hydrateFromObject({ themeId: currentTheme.id }));
    },
    [themes]
  );

  const { executeRequest: listThemesExecuteRequest } = useListThemes((themes) => {
    setThemes(themes);

    const settings = getLocalStorageSettings();

    if (themes.length === 0) {
      return;
    }

    setCurrentThemeById(settings?.themeId, themes);
  });

  // todo: move to ssr with new app directory?
  const fetchThemes = useCallback(async () => {
    await listThemesExecuteRequest({});
  }, [listThemesExecuteRequest]);

  const { executeRequest: updateProfileThemeExecuteRequest } = useUpdateProfileTheme();

  const changeCurrentTheme = useCallback(
    async (theme: ThemeModel) => {
      setCurrentTheme(theme);
      setLocalStorageSettings(SettingsModel.hydrateFromObject({ themeId: theme.id }));
      if (loggedIn && theme.id && theme.id !== currentTheme?.id) {
        await updateProfileThemeExecuteRequest({ body: { themeId: theme.id } });
      }
    },
    [loggedIn, updateProfileThemeExecuteRequest, currentTheme?.id]
  );

  // get languages
  const { executeRequest: listLanguagesExecuteRequest } = useListLanguages(
    (result) => {
      setLanguages(result);
    },
    async (error) => {
      errorToast(error);
      if (error.status === 403) {
        await logout();
      }
    }
  );

  const fetchLanguages = useCallback(async () => {
    await listLanguagesExecuteRequest({});
  }, [listLanguagesExecuteRequest]);

  // get user data
  const { executeRequest: getUserExecuteRequest, result: getUserResult } = useGetUserMe(
    async (result) => {
      const lsAuth = getLocalStorageAuthorization();
      if (!lsAuth) {
        return;
      }
      const localUser = LocalStorageUserModel.hydrateFromObject(result, jwtDecode(lsAuth));
      setUser(localUser);
      await setCookieUser(localUser.serialize());
      cookieUser.current = localUser;
    },
    async (error) => {
      errorToast(error);
      if (error.status === 403) {
        await logout();
      }
    }
  );

  // must be in an effect hook, because the onSuccess callback gets the themes only at the time of initialization and does not update the value again afterwards.
  useEffect(() => {
    if (getUserResult === null) {
      return;
    }

    // const authData = getAuthenticationHeader();
    const authData = getLocalStorageAuthorization();

    if (!authData) {
      // should never happen
      throw Error('Unexpected Error: no token found in local storage');
    }

    const localStorageUser = LocalStorageUserModel.hydrateFromObject(getUserResult, jwtDecode(authData));
    setUser(localStorageUser);
    setLocalStorageUser(localStorageUser.serialize());

    if (themes.length === 0) {
      return;
    }

    setCurrentThemeById(getUserResult.theme?.id);
  }, [getUserResult, setCurrentThemeById, themes.length]);

  const getUserData = useCallback(async () => {
    const localStorageSettings = getLocalStorageSettings();
    if (!cookieUser.current || !localStorageSettings) {
      await getUserExecuteRequest({});

      return;
    }

    setUser(cookieUser.current);
  }, [getUserExecuteRequest]);

  const reloadUser = async () => {
    await getUserExecuteRequest({});
  };

  const updateUserAsJson = async (userAsJson: string) => {
    await setCookieUser(userAsJson);
  };

  const updateUser = async (user: UserModel) => {
    if (!authorizationHeader) {
      // should never happen
      throw Error('Unexpected Error: authorizationHeader should exist');
    }
    if (!user.theme) {
      // should never happen
      throw Error('Unexpected Error: theme should exist in user');
    }

    const localStorageUser = LocalStorageUserModel.hydrateFromObject(user, jwtDecode(authorizationHeader));
    setUser(localStorageUser);
    await updateUserAsJson(localStorageUser.serialize());

    setLocalStorageSettings(SettingsModel.hydrateFromObject({ themeId: user.theme.id }));
  };

  const updateUsername = (username: string) => {
    if (!user) {
      // should never happen
      throw Error('Unexpected Error: user should exist');
    }
    if (!authorizationHeader) {
      // should never happen
      throw Error('Unexpected Error: authorizationHeader should exist');
    }

    const localStorageUser = LocalStorageUserModel.hydrateFromObject(
      {
        ...user,
        username,
      },
      jwtDecode(authorizationHeader)
    );
    setUser(localStorageUser);
    updateUserAsJson(localStorageUser.serialize());
  };

  const updateGravatar = (gravatar: UserImageSrcModel) => {
    if (!user) {
      // should never happen
      throw Error('Unexpected Error: user should exist');
    }
    if (!authorizationHeader) {
      // should never happen
      throw Error('Unexpected Error: authorizationHeader should exist');
    }

    const localStorageUser = LocalStorageUserModel.hydrateFromObject(
      {
        ...user,
        gravatar,
      },
      jwtDecode(authorizationHeader)
    );
    setUser(localStorageUser);
    updateUserAsJson(localStorageUser.serialize());
  };

  const login = useCallback(
    async (authorizationHeader: string, refreshTokenResponse?: string, refreshTokenExpirationResponse?: number) => {
      setLoggedIn(true);
      setAuthorizationHeader(authorizationHeader);
      if (refreshTokenResponse && refreshTokenExpirationResponse) {
        setRefreshToken(refreshTokenResponse);
        setRefreshTokenExpiration(refreshTokenExpirationResponse);
        setLocalStorageRefreshToken(refreshTokenResponse, refreshTokenExpirationResponse);
      }
      setLocalStorageAuthorization(authorizationHeader);
      // store in cookie, to have this also on SSR
      await setCookiesAuthorization(authorizationHeader, refreshTokenResponse, refreshTokenExpirationResponse);
      setAuthorizationHeaderForRequests(authorizationHeader, refreshTokenResponse);
      await getUserData();
      await listUserAchievementAllowedStatusExecuteRequest({});
    },
    [getUserData, listUserAchievementAllowedStatusExecuteRequest]
  );

  const logout = async () => {
    setUser(null);
    await deleteCookieUser();
    cookieUser.current = undefined;
    setAuthorizationHeader(null);
    setRefreshTokenExpiration(null);
    setRefreshToken(null);
    removeAuthorizationHeaderForRequests();
    deleteLocalStorageAuthorization();
    deleteLocalStorageUser();
    await deleteCookieRefreshToken();
    await deleteCookieAuthorization();
    await deleteCookieRefreshToken();
    setLoggedIn(false);
    resetRewardStore();
  };

  const initialize = useCallback(async () => {
    client.interceptors.request.use((request) => {
      request.headers.set('X-Locale', lng ?? DEFAULT_LANGUAGE_ISO);
      return request;
    });
    const localStorageAuthorization = getLocalStorageAuthorization();
    if (localStorageAuthorization) {
      await login(localStorageAuthorization);
    } else {
      await logout();
    }

    await fetchLanguages();
    await fetchThemes();
    initTypingBoxSettings();

    setIsGlobalContextInitialized(true);
  }, [fetchLanguages, fetchThemes, initTypingBoxSettings, login]);

  useEffect(() => {
    initialize().catch(unexpectedApiError);
  }, [initialize]);

  return {
    isGlobalContextInitialized,
    authorizationHeader,
    refreshToken,
    refreshTokenExpiration,
    loggedIn,
    login,
    logout,
    languages,
    setLanguages,
    user,
    updateUser,
    updateUsername,
    updateGravatar,
    reloadUser,
    themes,
    fetchThemes,
    currentTheme,
    changeCurrentTheme,
    typingBoxSettings,
    updateTypingBoxSettings,
  };
}
